const getItem = (key: string) => {
  const rawValue = localStorage.getItem(key);
  if (!rawValue) return null;
  return JSON.parse(rawValue)
};

const setItem = (key: string, value: unknown) => {
  return localStorage.setItem(
    key,
    JSON.stringify(value)
  );
};

const removeItem = (key: string) => {
  return localStorage.removeItem(key);
};

const clear = () => {
  return localStorage.clear()
};

const storage = {
  getItem,
  setItem,
  removeItem,
  clear,
}

export default storage;
