import { Container } from "@mui/material";
import React from "react";

const LeaveRequestHistory = () => {
  return <Container maxWidth="md">

  </Container>;
};

export default LeaveRequestHistory;
