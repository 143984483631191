import {useState, useEffect} from 'react';
import storage from '../services/storage/storage';

function useStoredState<T>(
  key: string,
  initialValue: T,
): [T | null, React.Dispatch<React.SetStateAction<T | null>>, boolean] {
  const [storedValue, setStoredValue] = useState<T | null>(initialValue);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const item = storage.getItem(key);
    setStoredValue(item);
    setInitialized(true);
  }, [key]);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    storage.setItem(key, storedValue)
  }, [storedValue, key, initialized]);

  return [storedValue, setStoredValue, initialized];
}

export default useStoredState;
