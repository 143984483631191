import {
  Box,
  Card,
  CardContent,
  Chip,
  Container,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import AllocationService from "../services/allocation/allocation";
import pluralize from "pluralize";
import dayjs from "dayjs";

const LeaveRequestAllocations = () => {
  const { data: myAllocations = [] } = useQuery({
    queryKey: ["allocations"],
    queryFn: async () => {
      const response = await AllocationService.getMy();
      return response.data;
    },
  });

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          marginTop: 2,
        }}
      >
        {myAllocations.map((allocation) => {
          return (
            <Card>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="h6"
                  component="h2"
                  sx={{
                    marginRight: 2,
                  }}
                >
                  {allocation.reason.text}
                </Typography>

                <Box>
                  <Typography>Allocated</Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {allocation.amount}
                  </Typography>
                </Box>

                <Box>
                  <Typography>Valid</Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    <span>
                      {dayjs(allocation.valid_from).format("MMM DD - YYYY")} -{" "}
                      {dayjs(allocation.valid_until).format("MMM DD - YYYY")}
                    </span>
                  </Typography>
                </Box>

                <Chip
                  label={`${allocation.days_left} ${pluralize(
                    "day",
                    allocation.days_left
                  )} left`}
                />
              </CardContent>
            </Card>
          );
        })}
      </Box>
    </Container>
  );
};

export default LeaveRequestAllocations;
