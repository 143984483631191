import React from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { PropsWithChildren } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuthContext } from "../contexts/AuthContext";
import { Outlet, useNavigate } from "react-router-dom";

const pages = [
  { name: "Leave request", path: "leave-request" },
  { name: "History", path: "leave-request-history" },
  { name: "Allocations", path: "leave-request-allocations" },
];


const Layout = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const { userDetails, logout } = useAuthContext();

  const settings = [
    { name: "Profile", handler: () => {} },
    {
      name: "Logout",
      handler: () => {
        localStorage.removeItem("token");
        logout()
      },
    },
  ];

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (path?: string) => () => {
    if (path) {
      navigate(path);
    }
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (fn: any) => () => {
    setAnchorElUser(null);
    if (fn) {
      fn();
    }
  };

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu()}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map(({ name, path }) => (
                  <MenuItem
                    key={path}
                    href={path}
                    onClick={handleCloseNavMenu(path)}
                  >
                    <Typography textAlign="center">{name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                flexGrow: { xs: 1, md: 0 },
                color: "inherit",
                textDecoration: "none",
              }}
            >
              Yojji Employee portal
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map(({ name, path }) => (
                <Button
                  key={path}
                  href={path}
                  onClick={handleCloseNavMenu(path)}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {name}
                </Button>
              ))}
            </Box>

            {!!userDetails && (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt={
                        userDetails.user.first_name +
                        " " +
                        userDetails.user.last_name
                      }
                    >
                      {userDetails.user.first_name[0] +
                        userDetails.user.last_name[0]}
                    </Avatar>
                  </IconButton>
                </Tooltip>

                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map(({ name, handler }) => (
                    <MenuItem key={name} onClick={handleCloseUserMenu(handler)}>
                      <Typography textAlign="center">{name}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}
          </Toolbar>
        </Container>
        {children}
      </AppBar>

      <Container maxWidth="md">
        <Outlet />
      </Container>
    </>
  );
};

export default Layout;
