import React from "react";
import { useAuthContext } from "./contexts/AuthContext";
import Login from "./pages/Login";
import LeaveRequest from "./pages/LeaveRequest";
import Layout from "./components/Layout";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import LeaveRequestHistory from "./pages/LeaveRequestHistory";
import LeaveRequestAllocations from "./pages/LeaveRequestAllocations";
import Profile from "./pages/Profile";

const InnerRouter = () => {
  const { userDetails } = useAuthContext();

  return (
    <Routes>
      {userDetails ? (
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="leave-request" />} />
          <Route path="leave-request" element={<LeaveRequest />} />
          <Route
            path="leave-request-history"
            element={<LeaveRequestHistory />}
          />
          <Route
            path="leave-request-allocations"
            element={<LeaveRequestAllocations />}
          />
          <Route path="profile" element={<Profile />} />
        </Route>
      ) : (
        <Route path="/" element={<Login />}></Route>
      )}

      <Route path="*" element={<Navigate to="/" />} />

      {/* <Route path="*" element={<NoMatchPage />} /> */}
    </Routes>
  );
};

const Router = () => (
  <BrowserRouter>
    <InnerRouter />
  </BrowserRouter>
);
//   const { userDetails } = useAuthContext();
//   return userDetails ? (
//     <Layout>
//       <Main />
//     </Layout>
//   ) : (
//     <Login />
//   );
// };

export default Router;
