import React, {PropsWithChildren, createContext, useContext} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import storage from '../services/storage/storage';
import useStoredState from '../hooks/useStoredState';

type UserDetails = {
  token: any;
  refreshToken: any;
  user: any;
};

type Context = {
  userDetails: UserDetails | null;
  initialized: boolean;
  setUserDetails: React.Dispatch<React.SetStateAction<UserDetails | null>>;
  logout: () => void;
};

const AuthContext = createContext<Context>({
  userDetails: null,
  setUserDetails: () => Promise.resolve(),
  initialized: false,
  logout: () => {},
});

export const AuthProvider = ({children}: PropsWithChildren) => {
  const queryClient = useQueryClient();

  const [userDetails, setUserDetails, initialized] =
    useStoredState<UserDetails | null>('userDetails', null);


  const logout = async () => {
    setUserDetails(null);
    queryClient.clear(); // clear all queries cache
    await storage.clear();
  };

  const value = {
    userDetails,
    setUserDetails,
    logout,
    initialized,
  };

  if (!initialized) {
    return <span>Loading</span>;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
