import { Box, Container, Typography } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import { useAuthContext } from "./../contexts/AuthContext";
import { useMutation } from "@tanstack/react-query";
import authService from "./../services/auth/auth";

function Login() {
  const { setUserDetails } = useAuthContext();

  const login = useMutation({
    mutationFn: authService.login,
    onSuccess: setUserDetails,
    onError: (err) => {
      console.error("Login Failed", err)
    }
  });

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5" sx={{ marginBottom: 4 }}>
          Sign in to Employee portal
        </Typography>

        <GoogleLogin
          onSuccess={(credentialResponse) => {
            console.log("login success", credentialResponse);
            // setUserDetails(credentialResponse)
            // login({
            //   idToken: credentialResponse.credential,
            // }).catch(() =>
            //   notify("Invalid email or password", {
            //     type: "error",
            //   })
            // );
            if (!credentialResponse.credential) {
              console.error("No credential found");
              return;
            }

            login.mutate(credentialResponse.credential)
          }}
          onError={() => {
            console.log("Login Failed");
            // notify("Login Failed", {
            //   type: "error",
            // });
          }}
        />
      </Box>
    </Container>
  );
}

export default Login;
