import apiClient from "../apiClient";

const LeaveRequestCategoryService = {
  getAll: async (): Promise<{
    data: any[];
  }> => {
    const res = await apiClient.get("/leaveRequestCategory");
    return res.data;
  },
};

export default LeaveRequestCategoryService;
