import axios from "axios";

const apiClient = axios.create({});

apiClient.defaults.baseURL = process.env.REACT_APP_BASE_URL;
apiClient.defaults.headers.post["Content-Type"] = "application/json";

apiClient.interceptors.request.use((config) => {
  const userDetails = localStorage.getItem("userDetails");
  const parsedUserDetails = userDetails ? JSON.parse(userDetails) : null;
  if (parsedUserDetails) {
    config.headers.Authorization = `Bearer ${parsedUserDetails.token}`;
  }
  return config;
});

apiClient.interceptors.request.use(async (config) => {
  const userDetails = localStorage.getItem("userDetails");
  const parsedUserDetails = userDetails ? JSON.parse(userDetails) : null;
  if (!parsedUserDetails) {
    return config;
  }
  const token = parsedUserDetails.token;
  const refreshToken = parsedUserDetails.refreshToken;

  const tokenPayload = token ? JSON.parse(atob(token.split(".")[1])) : null;

  if (tokenPayload?.exp && tokenPayload.exp * 1000 < Date.now()) {
    const authResponse = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/auth/refresh`,
      {},
      {
        headers: {
          Authorization: "Bearer " + refreshToken,
        },
      }
    );

    config.headers.Authorization = `Bearer ${authResponse.data.token}`;
    localStorage.setItem("userDetails", JSON.stringify({
      ...parsedUserDetails,
      token: authResponse.data.token,
      refreshToken: authResponse.data.refreshToken,
    }));
    apiClient.defaults.baseURL = authResponse.data.token;
  }

  return config;
});

export default apiClient;
