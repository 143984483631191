import {
  Alert,
  Box,
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useQuery } from "@tanstack/react-query";
import LeaveRequestCategoryService from "../services/leaveRequestCategory/leaveRequestCategory";
import AllocationService from "../services/allocation/allocation";
import pluralize from "pluralize";
import { Controller, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

const validationSchema = z.object({
  reason: z.number(),
  allocation: z.number().optional().nullable(),
  willWorkOnOtherDays: z.string().optional().nullable(),
  date: z.array(z.any()).length(2),
  comment: z.string().optional().nullable(),
});

const LeaveRequestForm = () => {
  const { control, handleSubmit } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      reason: null,
      allocation: null,
      willWorkOnOtherDays: null,
      date: [null, null] as [Dayjs | null, Dayjs | null],
      comment: null,
    },
  });

  const { data: leaveRequestCategories = [] } = useQuery({
    queryKey: ["leaveRequestCategories"],
    queryFn: async () => {
      const response = await LeaveRequestCategoryService.getAll();
      return response.data;
    },
  });

  const { data: myAllocations = [] } = useQuery({
    queryKey: ["allocations"],
    queryFn: async () => {
      const response = await AllocationService.getMy();
      return response.data;
    },
  });

  const reason = useWatch({
    control,
    name: "reason",
  });

  const filteredAllocations = myAllocations.filter((allocation) => {
    return allocation.reason_id === reason;
  });

  const available = myAllocations.reduce((acc, allocation) => {
    return acc + allocation.amount;
  }, 0);

  const onSubmit = (data: any) => {
    console.log({ data });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Alert
        severity="info"
        sx={{
          marginTop: 2,
        }}
      >
        Available to you: {available} days
      </Alert>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Controller
            name="reason"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  select
                  label="Reason"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  error={!!fieldState.error}
                  {...field}
                  helperText={fieldState.error?.message}
                >
                  {leaveRequestCategories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.text}
                    </MenuItem>
                  ))}
                </TextField>
              );
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="allocation"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  select
                  label="Allocation"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  disabled={filteredAllocations.length === 0}
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error?.message ||
                    "Note: some reasons must be specified without allocations"
                  }
                  {...field}
                >
                  {filteredAllocations.map((allocation) => {
                    return (
                      <MenuItem key={allocation.id} value={allocation.id}>
                        {allocation.reason.text} ({allocation.amount}{" "}
                        {pluralize("day", allocation.amount)} until{" "}
                        {dayjs(allocation.valid_until).format("YYYY-MM-DD")} )
                      </MenuItem>
                    );
                  })}
                </TextField>
              );
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <Box
            sx={{
              marginTop: 2,
              marginBottom: 1,
            }}
          >
            <Controller
              name="date"
              control={control}
              render={({ field, formState }) => {
                const [date_form_error, date_to_error] = (formState.errors
                  .date as any) || [null, null];

                return (
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateRangePicker
                        localeText={{ start: "Start date", end: "End date" }}
                        {...field}
                      />
                    </LocalizationProvider>
                    <FormHelperText error={date_form_error || date_to_error}>
                      {date_form_error?.message || date_to_error?.message}
                    </FormHelperText>
                  </>
                );
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="willWorkOnOtherDays"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  label="Work day(s)"
                  type="date"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  {...field}
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error?.message ||
                    "Note: Dates must be specified if you plan to work the other day(s)"
                  }
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="comment"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  label="Comment (optional)"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  multiline
                  rows={4}
                  {...field}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" color="primary" type="submit">
            Request
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default LeaveRequestForm;
