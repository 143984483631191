import apiClient from "../apiClient";

const AllocationService = {
  getMy: async (): Promise<{
    data: any[];
  }> => {
    const res = await apiClient.get("/allocation/me");
    return res.data;
  },
};

export default AllocationService;
