import apiClient from "../apiClient";

const authService = {
  login: async (
    idToken: string
  ): Promise<{
    token: string;
    refreshToken: string;
    user: {
      id: string;
      email: string;
      name: string;
    };
  }> => {
    const res = await apiClient.post("/auth/google/login", { idToken });
    return res.data;
  },
};

export default authService;
